// Выпадающее меню во всю ширину с отступом
.dropdown-menu {
  width: 100%;
  height: auto!important;
  margin-top: 5px !important;
  .dropdown-item {
    white-space: normal!important;
  }

  .dropdown-item:nth-child(odd) {
    background-color: #f5f5f5;
  }
  .dropdown-item.active:nth-child(odd) {
    background-color: var(--bs-dropdown-link-active-bg);
  }

}

// Белый фон для контроллов с аттрибутом readonly
.form-control[readonly] {
  background-color: #ffffff;
}

// Свайпер
.swiper-slide {
  width: auto;
  display: inline-block;
}

// SWITCH
.form-switch {
  padding: 0;
  display: flex;

  label {
    text-align: left;
    display: block;
    padding: 1rem calc(var(--bs-gutter-x) * .5);
    font-size: 1rem;
    font-weight: 400;
    color: #212529;
    transition: border-color 0.15s ease-in-out, box-shadow-sm 0.15s ease-in-out;
  }

  .form-check-input {
    width: 36px !important;
    height: 20px;
    margin: 18px 14px 0 0;
  }
}

// ALERTS
.alert.alert-my-info {
  background-color: #EFF8FF;
  border-color: #EFF8FF;
  color: #626F80;
}

.alert.alert-my-info-gray {
  background-color: #F2F4F7;
  border-color: #F2F4F7;
  color: #344054;
}

// Календарь
.bs-datepicker-container {
  padding: 0;
}

.bs-datepicker-body {
  border: none;
}

// Raiting
.bs-rating-star {
  color: #E4E7EC;
  font-size: 18px;
  &.active {
    color: #FFA717;
  }
}

// PDF MODAL
.PDFmodal {
  min-width: 95%;

  .modal-footer {
    padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);
  }
}
// END PDF MODAL

// PROGRESS CIRCLE
.progress-block.card {
  overflow: visible;
  height: 150px;
}

.progress-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.progress-circle {
  transform: rotate(-90deg); /* Поворот для начала сверху */
}

.progress-circle-bg {
  stroke: #dee3e9; /* Серый цвет фона */
}

.progress-circle-fg {
  stroke: #007bff; /* Синий цвет для прогресса */
  stroke-linecap: round;
  transition: stroke-dasharray 0.3s;
}

.timer {
  position: absolute;
  font-size: 15px;
  font-weight: bold;
  color: #9299a4;
}

.progress-circle-text {
  position: absolute;
  font-size: 15px;
  line-height: 15px;
  font-weight: 400;
  text-align: center;
  bottom: -30px;
}

// END PROGRESS CIRCLE
