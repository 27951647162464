@import url(https://db.onlinewebfonts.com/c/653d9381828e9577fb1e417dc047f89d?family=SuisseIntl-SemiBold);
@import url(https://db.onlinewebfonts.com/c/88f10bf18a36407ef36bf30bc25a3618?family=SuisseIntl-Regular);

:root {
  --icon-fill-color: #ED5335;
  --bs-card-bg: #F2F4F7;
}

$primary: #ED5335;
$gray: #9EA0A1;
$form-feedback-icon-valid: none;
$form-feedback-icon-invalid: none;
$border-radius: 32px;
$border-radius-card: 24px;
$input-border-radius: 32px;
$box-shadow-sm: none;
$btn-border-radius-lg: 16px;
$btn-border-radius-sm: 16px;
$input-btn-border-width: 2px;
$input-border-color: #E4E7EC;
$header-bg: #F6F7FF;
$card-bg: #F3F5F9;
$card-title-color: #24272A;
$card-text-color: #9EA0A1;

@import 'bootstrap/scss/bootstrap';
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";

body {
  background-color: #ffffff;
  font-family: "SuisseIntl-Regular", sans-serif;
}

// BTN
.btn {
  border-radius: $input-border-radius;
}

.btn-primary, .btn-light {
  --bs-btn-color: #ffffff;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-active-color: #ffffff;

  &:active:focus {
    box-shadow: none;
  }

  &:hover {
    box-shadow: none;
  }

  &:focus {
    box-shadow: none;
  }
}

.btn-check:focus + .btn-primary, .btn-primary:focus {
  color: #fff;
  background-color: $primary;
  border-color: $primary;
  box-shadow: none;
}

.btn-outline-primary {
  --bs-btn-hover-color: #ffffff;
  --bs-btn-active-color: #ffffff;
}

.btn-check:focus + .btn-outline-primary, .btn-outline-primary:focus {
  box-shadow: none;
}

.btn-check:active + .btn-outline-primary:focus, .btn-check:checked + .btn-outline-primary:focus, .btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus, .btn-outline-primary:active:focus {
  box-shadow: none;
}

.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='rgb(20, 143, 43)'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
  transform: rotate(-180deg);
}

.btn-link {
  color: $primary !important;
}

a:hover {
  color: $primary !important
}

.btn-outline-primary.btn-label-check {
  color: $gray;
  font-size: 15px;
  font-weight: 400;
  border: none;
  background: $white;
  padding: 8px 16px 8px 16px;

  &:hover {
    background: $white;
    color: $primary;
  }
}
// END BTN

// ACCORDION
.accordion {
  border-radius: $border-radius-xl;
}

.accordion-button:not(.collapsed) {
  color: $primary;
  background-color: transparent;
  //box-shadow: $box-shadow-sm;
}

.accordion-button:focus {
  z-index: 3;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: none;
}
// END ACCORDION

// FORM CONTROL
$form-validation-states: (
  "valid": (
    "color": $form-feedback-valid-color,
    "icon": $form-feedback-icon-valid
  ),
  "invalid": (
    "color": $form-feedback-invalid-color,
    "icon": $form-feedback-icon-invalid
  )
) !default;

.form-floating > .form-control {
  @include border-radius($input-border-radius, 0);
}

.form-control:focus, .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  box-shadow: $box-shadow-sm;
}
// END FORM CONTROL

// Календарь
.bs-datepicker {
  border-radius: $border-radius;
}

.bs-datepicker-container {
  padding: 0;
  border-radius: $border-radius;
}

.theme-default .bs-datepicker-head {
  background-color: $primary;
  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;
}

// CARD
.select-company-filter.card {
  background: #F2F4F7;
}

.card {
  background-color: #F2F4F7;
}
// END CARD

// ALERT
.alert {
  padding: 12px 16px;
  align-items: center;
  border-radius: 16px;
  background: #408AD41A;
  color: #344054;
  border: none;
}

// end ALERT

// SelectObjectComponent
.selectObjectComponent {
  @include media-breakpoint-down(sm) {
    .objectsInsurer {
      order: 0;
      margin-bottom: 16px;
    }
    .mortgagePropertyTypes {
      order: 1;
    }
  }

  .alertList {

  }
}

// end SelectObjectComponent

// OFFERS
.badge {
  color: #000000;
  background: #d3d3d3;
  margin: 0 10px 10px 0;
}

.btn-gift {
  display: flex;
  align-items: center;
  height: 100%;

  &:hover {
    .gift-icon {
      fill: $white;
    }
  }

  .gift-icon {
    fill: $primary;
  }
}
// end OFFERS

// Иконка на странице success
.success-icon {
  svg {
    rect {
     fill: $primary;
    }
 }
}

.success-list-item {
  svg {
    fill: $primary;
  }
}
// END Иконка на странице success

// MAIN PAGE
.mortgage-app {
  margin-bottom: 40px;
  @include media-breakpoint-down(sm) {
    margin-bottom: 24px;
  }
}

.main-page-title {
  display: none;
}

.top-info {
  background-color: $header-bg;
  border-radius: $border-radius;
  margin-top: 30px;
  margin-bottom: 40px;

  @include media-breakpoint-down(sm) {
    margin-bottom: 25px;
  }

  .main-img, .main-img-mobile {
    border-radius: $border-radius;
  }

}

// advantages
.advantages {
  background-color: $card-bg;
  border-radius: $border-radius;
  padding: calc(var(--bs-gutter-x) * 0.75);

  @include media-breakpoint-down(sm) {
    padding: calc(var(--bs-gutter-x) * 0.5);
  }

  .card {
    background: $white;
    border-radius: $border-radius-card;
  }

  .advantage-title {
    font-size: 15px;
    line-height: 22px;
    color: $card-title-color;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .advantage-text {
    font-size: 16px;
    line-height: 24px;
    color: $card-text-color;
  }
}
// polis
.polis-question-block {
  margin-bottom: 40px;
  @include media-breakpoint-down(sm) {
    margin-bottom: 24px;
  }

  .polis-question-title {
    font-size: 24px;
    line-height: 32px;
    color: $card-title-color;
  }

  .card {
    border-radius: $border-radius;
    background-color: $card-bg;

    .card-title {
      font-size: 24px;
      line-height: 32px;
      color: $card-title-color;
    }

    .card-text {
      font-size: 16px;
      line-height: 24px;
      color: $card-text-color;
    }
  }

}

// faq
.faq {
    font-size: 15px;
    line-height: 22px;
    color: $card-title-color;
}

.faq-heading {
    font-size: 24px;
    line-height: 32px;
    color: $card-title-color;
}

.faq-title {
  font-size: 15px;
  line-height: 22px;
  color: $card-title-color;
}

.faq-text {
  font-size: 15px;
  line-height: 22px;
  color: $card-text-color;
}

// footer
.footer-logo {
  margin-bottom: 5px;
  margin-right: 20px;
}

.footer-text {
  font-size: 15px;
  line-height: 22px;
  color: $card-text-color;

  @include media-breakpoint-down(sm) {
      font-size: 10px;
      line-height: 16px;
  }
}

// end MAIN PAGE
